import React from 'react';

const NotFound = () => {
     return (
          <div>
               <h1>Sorry, this page doesn't exist.</h1>
          </div>
     )
}

export default NotFound;
